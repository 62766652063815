<template>
  <div>
    <v-container grid-list-md fluid>
      <v-layout row wrap>
        <v-flex xs12 md12>
          <v-tabs centered slider-color="primary" v-model="tabs">
            <v-tab>Canais de vendas</v-tab>
            <v-tab-item>
              <CanalLista />
            </v-tab-item>
            <!-- <v-tab>Lojas</v-tab>
            <v-tab-item>
              <MonitoramentoOS />
            </v-tab-item> -->
          </v-tabs>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
const CanalLista = () => import('./Canal-Lista')

export default {
  name: 'CanalTabs',
  components: {
    CanalLista
  },
  created () {
    this.$eventHub.$on('CadastroTabs-changeTab', (tab) => this.tabs = tab)
  },
  beforeDestroy () {
    this.$eventHub.$off('CadastroTabs-changeTab')
  },
  data () {
    return {
      tabs: 0
    }
  }
}
</script>